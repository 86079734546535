<template>
    <div class="dashCard">
        <a-spin tip="Loading..." v-show="isLoading">
            <div class="spin-content"></div>
        </a-spin>
        <div class="vue-draggable-handle">
            <i class="lowcode icon-tuozhuai"></i>
        </div>
        <div class="no-drag">
            <div class="card-head">
                <span class="card-head-title">
                    <slot name='title'></slot>
                </span>
                <span class="card-head-aside" :style="{marginRight: operateWidth + 'px'}">
                    <a-space>
                        <slot name='aside'></slot>
                    </a-space>
                </span>
                <span class="card-head-operate">
                    <a-space>
                        <slot name='operate'></slot>
                        <i class="el-icon-full-screen" v-if="operateInfo.isScreen" @click.stop="changeScreen"></i>
                        <a-dropdown :trigger="['click']" >
                            <i class="lowcode icon-gengduo2"></i>
                            <a-menu slot="overlay" @click="handleMenuClick">
                                <!-- <a-menu-item key="copyCard"  v-if="operateInfo.isCopy" > 
                                    
                                    <img src="@/assets/imgs/echarts/abb_copy_32.svg" style="width: 16px; height: 16px; position: absolute; top: 20px;" alt="">
                                    <span style="margin-left: 20px">复制</span>
                                </a-menu-item> -->
                                
                                

                                <a-menu-item key="delCard"  v-if="operateInfo.isDel" >
                                    <!-- <i class="lowcode icon-shanchu3"></i> -->
                                    <img src="@/assets/imgs/echarts/abb_trash_32.svg" style="width: 16px; height: 16px; position: absolute; top: 20px;" alt="">
                                    <span style="margin-left: 20px">删除</span>
                                </a-menu-item>
                                <a-menu-item key="configCard"  v-if="operateInfo.isConfig&&gridOption.component == 'lvscBox21'" > 
                                    <!-- <i class="lowcode icon-a-5shezhi"></i> -->
                                    <img src="@/assets/imgs/echarts/abb_settings_32.svg" style="width: 16px; height: 16px; position: absolute; top: 60px;" alt="">
                                    <span style="margin-left: 20px">配置</span>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>

                    </a-space>
                </span>
            </div>
            <div class="card-content">
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'dashCard',
    components: {},
    props: {
        gridOption: {
            type: Object,
            default: function() {
                return { w: 6, h: 6 }
            }
        },
        operateWidth: {//操作列宽度
            type: Number,
            default: 64
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogVisible:false,
        }
    },
    computed:{
        // 当前驾驶舱
        allwidget() {
            return this.$store.state.allwidget
        },
        operateInfo() {
            var operate = {
                isScreen: true,
                isCopy: true,
                isDel: true,
                isConfig: true
            }
            if(this.gridOption.operate) {
                Object.assign(operate,this.gridOption.operate)
            }
            return operate
        }
    },
    methods: {
        handleMenuClick(e) {
            
            console.log('click', e);
            this[e.key]()
        },
        // 删除card
        delCard() {
            
            this.gridOption.isDel = true//移除标志位
            // 执行保存并更新当前驾驶舱数据
            
            this.$store.commit("setisDelwidget", true)

        },
        // 复制
        copyCard() {
            console.log(this.gridOption,"copyCard",this.allwidget)
                const url = Config.dev_url + "/api-apps/panel/updata";
                var data = {
                    id:this.allwidget.id,
                    value: []
                }
                
                var arr = JSON.parse(JSON.stringify(this.allwidget.value))
                this.setLayout(arr)
                data.value = JSON.stringify(arr)
                this.$axios.post(url,data ).then((res) => {
                    if(res.data.code == 0) {
                        this.visible = false
                        this.$message.success(res.data.msg)
                        this.$store.commit("setisRequestPanelInfo", true)
                    }else {
                        this.$message.warning(res.data.msg)
                    }
                })
                .catch((err) => {});
        },
        /**
         * 设置页面布局
         * */ 
        setLayout: function(arr=[]) {
                var maxX = 12;//x轴长度
                var xAxis = new Array(maxX).fill(0);//横坐标分为12份
                var mIdx = 0
                if(arr.length>0) {
                    arr.forEach((layout,idx)=>{
                        layout.i = idx
                        var useArr = xAxis.slice(layout.x,layout.x+layout.w);//截取图形放置区域
                        var maxValue = Math.max(...useArr);//取最大值 即最大y值
                        for(let i= 0;i<layout.w;i++) {
                            xAxis[layout.x+i] = maxValue + layout.h;//区域 赋值
                        }
                        mIdx = idx
                    })
                }
                var checkList = [{
                    layout: this.gridOption
                }]
                checkList.forEach((item,idx)=>{
                    var {layout} = item
                    var trueIdxList = [];//真实下标
                    var maxHList = [];//可放置区域 防止后 的 最大y

                    for(let i = 0;i<= maxX - layout.w;i++) {//循环放置 可放区域
                        var useArr = xAxis.slice(i,i+layout.w)
                        var maxValue = Math.max(...useArr)
                        maxHList.push(maxValue)
                        trueIdxList.push(i)
                    }
                    var minNum = Math.min(...maxHList);//取 放置后 最矮的
                    var xIdx = maxHList.indexOf(minNum);//取下标
                    var startX = trueIdxList[xIdx]
                    var startY = minNum
                    
                    var obj = {
                        i: mIdx + idx,
                        x: startX,//x坐标
                        y: startY,//y坐标
                        Draggable: true,//是否可移动
                        isResizable: true,//是否可拖拽大小
                    }
                    mIdx++
                    Object.assign(layout,obj)
                    arr.push(layout)

                    for(let i = 0;i<layout.w;i++) {//区域 赋值
                        var value = minNum + layout.h
                        xAxis[startX + i] = value
                    }
                    console.log(xAxis,"--------xAxis",layout.cname,layout.w,layout.h)
                })

            },

        // 配置
        configCard() {
            console.log(this.gridOption)
            if(this.gridOption.component == "lvscBox21"){
                console.log(this.$store.state.showimgconfig)
                // if( this.$store.state.showimgconfig ==true){
                //     this.$store.commit("setshowimgconfig", false);
                //     // this.$store.commit("setshowimgconfig", true);
                // }else{
                //     this.$store.commit("setshowimgconfig", true);
                // }
                this.$store.commit("setshowimgconfig", true);
                console.log(this.$store.state.showimgconfig)
            }
        },


        changeScreen() {
            this.$emit("changeScreen")
        },
    },
}
</script>

<style lang="less" scoped>
/deep/.ant-spin-nested-loading {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    width: inherit;
    .ant-spin {
        max-height: 100% !important;
        height: 100%;
        width: 100%;
        border: 1px solid #91d5ff;
        background-color: rgba(237, 241, 243,.5);
    }
}
.dashCard {
    width: inherit;
    height: inherit;
    background: #ffffff;
    border-radius: 2px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    .card-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:16px 20px;
        padding-bottom: 10px;
        flex-wrap: wrap;
        .card-head-title {
            margin-left: 24px;
            font-size: 16px;
            font-weight: 600;
            color: #1f1f1f;
            line-height: 32px;
        }
        .card-head-aside {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            position: relative;
            div {
                &:nth-last-child(2) {
                    margin-left: 8px;
                }
            }
        }
        .card-head-operate {
            position: absolute;
            top: 20px;
            right: 16px;
            display: flex;
            i {
                color: #464646;
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                text-align: center;
                padding: 4px;
                &:hover {
                    background: rgba(51, 102, 255,.2);
                    color: #3366ff;
                    border-radius: 2px;
                    cursor: pointer;
                }
            }
            div {
                &:nth-last-child(2) {
                    margin-left: 8px;
                }
            }
        }
    }
    .no-drag {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px;
        padding-top: 0;
        box-sizing: border-box;
        padding-bottom: 12px !important;
    }
    .vue-draggable-handle {
        position: absolute;
        top: 20px;
        left: 16px;
        width: 24px;
        height: 24px;
        padding: 4px;
        box-sizing: border-box;
        text-align: center;
        line-height: 16px;
        color: #bababa;
        transition: all .3s cubic-bezier(.645,.045,.355,1);
        &:hover {
            background: rgba(51, 102, 255,.2);
            color: #3366ff;
            border-radius: 2px;
            cursor: pointer;
        }
        &:active {
            background: rgba(51, 102, 255,.2);
            color: #3366ff;
            border-radius: 2px;
            cursor: move;
        }
    }

    .ant-calendar-picker {
        width: 115px;
    }
}
.Awarpblue {
    .dashCard  {
        background: #205DE8;
        .card-head-title {
            color: #FFFFFF;
        }
        .card-head-operate {
            i {
                color: rgba(255,255,255,0.5);
                transition: all .3s cubic-bezier(.645,.045,.355,1);

                &:hover {
                    background: rgba(208,222,255,0.6);
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>